.top-text{
    margin-bottom: 20px;
}

.bottom-text{
    font-size: 16px;
    line-height: 1.3rem;
}

/*Media query*/
@media (max-width: 760px){
    .top-text {
        font-size: 22px;
    }

    .bottom-text {
        font-size: 17px;
    }
}