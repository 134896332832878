.three-row-img{
    margin-bottom: 20px;
    height: 50px;
}

/*Media query*/
@media (max-width: 760px){
    .three-row-img {
        margin-bottom: 10px;
        height: 70px;
    }
}