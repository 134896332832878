.clickable-area {
    background-color: rgba(19, 24, 77, 0.04);
    height: 70px;
    width: 70px;
    border-radius: 13px;
    margin-right: 20px;
    background-size: cover;
}

.clickable-area img{
    height: 20px;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
}

.custom-file-input{
    border: none;
    height: 100%;
    width: 100%;
}
.custom-file-input:focus{
    outline: none;

}

.custom-file-input:hover::before {
    border-color: none;
}

/*.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}*/

/*Media query*/
@media (max-width: 760px) {
    .img-text-upload{
        margin-top: 20px;
    }
}