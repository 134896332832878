.footer-icon{
    margin-bottom: 30px;
}

.footer-description {
    margin-top: 30px;
}

.company-section {
    margin-right: 20%;
}

.footer-left, .footer-right{
    width: 50%;
}

.footer-right{
    justify-content: flex-end;
}

.footer-links {
    margin-top: 30px;
}

.footer-divider{
    opacity: 0.05
}

.copyright-info{
    margin-top: 10px;
    margin-bottom: 40px;
}

.footer-links li {
    padding-bottom: 14px;
    font-size: 15px;
}

    .footer-links li:last-child {
        padding-bottom: 0;
    }

    .footer-links li a {
        text-decoration: none;
    }

    .footer-links li a:hover {
        text-decoration: none;
    }

        .footer-links li a .opacity-3:hover {
            opacity: 1;
            transition: background 0.8s;
        }

/*Media query*/
@media (max-width: 760px){
    .first-layer, .copyright-info, .single-column {
        flex-wrap: wrap;
    }

    .quick-link-section, .company-section {
        margin-top: 60px;
    }
    .company-section {
        margin-right: 0;
    }
    .footer-left, .footer-right, .company-section, .quick-link-section {
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .footer-description{
        margin-bottom: 20px;
    }

    .store-button-container{
        margin: 0 auto;
    }
    .store-button-cum-description {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
    }
}