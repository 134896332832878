.fifth-section div{
    margin-right: 10%;
}

.fifth-section div:last-child {
    margin-right: 0;
}

/*Media query*/
@media (max-width: 760px) {
    .fifth-section {
        flex-wrap: wrap;
        text-align: center;
        padding-top: 90px;
        padding-bottom: 90px;
    }

        .fifth-section div {
            margin-right: 0;
            margin-bottom: 30px;
        }

            .fifth-section div:last-child {
                margin-bottom: 0;
            }
}