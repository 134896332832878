.upload-bottom-text {
    color: #B7BAC7;
}

.contact-form {
    padding: 50px 60px;
}

.upload-national-id {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 20px;
    border: 1px solid #D6D8DE;
}

.upload-national-id > div{
    width: 100%;
    padding: 0 5%;
}

.language-cum-dob{
    display: flex;

}
.language{
    margin-right: 1%;
}
.language, .dob{
    width: 50%;
}
/*Media query*/
@media (max-width: 760px) {
    .contact-image {
        flex-direction: column;
    }

    .language-cum-dob {
        display: block;
    }

    .language {
        margin-right: 0;
    }

    .language, .dob {
        width: 100%;
    }
    .contact-form button.form-button {
        width: 80%;
    }

    .upload-national-id > div{
        flex-direction: row-reverse;
    }
}