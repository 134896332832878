.extra-info {
    color: #B7BAC7;
    font-size: 14px;
    margin-top: 20px;
    line-height: 1rem;
}

.top-info-register {
    color: #B7BAC7;
}

    .top-info-register a {
        text-decoration: none;
        font-weight: bold;
        color: #B7BAC7;
    }

    .extra-info span {
        color: #13184D;
        font-weight: bold;
    }

.readonly-tel{
    width: 20%;
    margin-right: 3%;
}

.fill-tel{
    width: 80%;
}

.register-form {
    flex: 1;
    display: flex;
    flex-direction: column;
}


/*Media query*/
@media (max-width: 760px) {
    .extra-info {
        padding-bottom: 40px;
    }

    .register-form {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }
}

