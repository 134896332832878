.third-section {
    padding-top: 120px;
    padding-bottom: 180px;
}

.third-section div {
    margin-right: 10%;
}

    .third-section div:last-child {
        margin-right: 0;
    }

/*Media query*/
@media (max-width: 760px){
    .third-section {
        flex-wrap: wrap;
        text-align: center;
        padding-top: 90px;
        padding-bottom: 90px;
    }

        .third-section div {
            margin-right: 0;
            margin-bottom: 30px;
        }
            .third-section div:last-child {
                margin-bottom: 0;
            }
}