.summary-title {
    margin-right: 1%;
    color: #B7BAC7;
    width: 30%;
}

.contact-form {
    padding: 50px 60px;
}

    .contact-form button.form-button {
        text-align: center;
    }

.summary-item{
    margin-bottom: 30px;
}

.summary-image{
    margin-bottom: 40px;
}

/*Media query*/
@media (max-width: 760px) {
    .summary-title{
        width: 100%;
        margin-bottom: 7px;
    }
    .summary-image {
        flex-direction: column;
    }
    .summary-image > p {
        display: none;
    }
    .summary-item{
        flex-direction: column;
    }
}