.small-text-newsletter {
    margin-top: 15px;
    color: rgba(255, 255, 255, 0.3);
}

.last-small-text{
    margin-top: 3px;
    margin-bottom: 20px;
}
.small-text{
    font-size: 13px;
}
/*Media query*/
@media (max-width: 760px){
    .sixth-section .app-button {
        width: 100%;
        color: #13184D !important;
    }
}
