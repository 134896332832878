.congratulation-title{
    font-size: 20px;
    margin-top: 10%;
    margin-bottom: 10px;
}

.congratulation-description {
    font-size: 17px;
    margin-bottom: 30px;
}

.congratulation-extra {
    font-size: 14px;
}

.congratulation-image{
    height: 250px;
}

.congrats-extra-head {
    margin-top: 10px;
    margin-bottom: 7px;
}

.congrats-extra-desc {
    margin-bottom: 20px;
}


/*Media query*/
@media (max-width: 760px) {
    .form-container {
        padding: 0;
    }
    .congratulations.auth-background{
        height: auto;
        padding-top: 0;
    }
}