.fixed-height-50 {
    height: 50px;
}
.no-side-left-side-border {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.subscription-input {
    border: none;
    padding: 0 20px;
    background-color: white;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
}

.subscription-input:focus{
    border: none;
    outline: none;
}

.negative-margin-left-2{
    margin-left: -2px;
}

.sub-override {
    padding-top: 15px;
    padding-left: 20px;
}

/*Media query*/
@media (max-width: 760px){
    .subsciption-input-container{
        flex-wrap: wrap;
        width: 100%;
    }

    .no-side-left-side-border {

        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }

    .subscription-input {
        border: none;
        padding: 0 20px;
        background-color: white;
        border-radius: 25px;
        margin-bottom: 20px;
    }

    .sub-override {
        padding-right: 30px
    }

    
}