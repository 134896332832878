.navigation{
    padding-top: 20px;
    padding-bottom: 20px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
}

.nav-rider{
    font-size: 20px;
    margin-bottom: 60px;
}

.nav-links{
    font-size: 17px;
    margin-bottom: 50px;

}

.nav-links a{
    text-decoration: none;
    color: white;
    font-weight: 100;

}

.nav-store-button button{
    background-color: white;
}

.content {
    top: 0;
    left: 0;
    bottom: 0;
}
.whole-area, .main-portion {
    top: 0;
    left: 0;
    height: 100%;
}
.whole-area {
}
.main-portion > div {
    padding: 100px 40px;
}

.nav-open  {
    animation: fadeIn 0.5s ease forwards;
}

    .nav-open > .main-portion {
        animation: slidein 0.5s ease forwards;
    }
        .nav-open > .main-portion > div {
            animation: appear 0.5s ease forwards;
        }

.nav-close {
    animation: fadeOut 0.5s ease forwards;
}
    .nav-close > .main-portion {
        animation: slideout 0.5s ease forwards;
    }

    .nav-close > .main-portion > div {
       display: none;
    }

@keyframes slidein {
    from {
        width: 0;
    }

    to {
        width: 70%;
    }
}

@keyframes slideout {
    from {
        width: 70%;
        display: block;
    }

    to {
        width: 0;
        display: none;
    }
}

@keyframes fadeIn {
    from {
        width: 100%;
        background-color: rgba(255,255,255,0);
    }

    to {
        width: 100%;
        background-color: rgba(255,255,255,0.85);
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }

    75%{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        width: 100%;
        display: block;
    }

    to {
        width: 0;
        display: none;
    }
}

/*Media query*/
@media (max-width: 760px){
    .icon{
        font-size: 20px;
    }
}