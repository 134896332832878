.login-background {
    background-image: url("../../assets/images/loginBg.png");
    background-size: cover;
    
}


/*Media query*/
@media (max-width: 760px) {
    .login-background {
        background: none;
        background-color: white;
    }
    .login-background .auth-text{
        width: 30%;
    }
}