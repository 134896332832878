/*
    Ripple effect gotton from csstricks at 
    https://css-tricks.com/how-to-recreate-the-ripple-effect-of-material-design-buttons 
    Thank you so much

*/

.app-button {
    padding: 20px 40px;
    border: none;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    background-position: center;
    transition: background 0.8s;
    cursor: pointer;
}

    .app-button:hover {
        background: #FFC942 radial-gradient(circle, transparent 1%, #FFC942 1%) center/15000%;
    }
    .app-button:active {
        background-color: #3F4BD0;
        background-size: 120%;
        transition: background 0s;
    }
.app-button:focus{
    outline: none;
}