.input-detail, .label-detail{
    font-size: 20px;
    padding-left: 0;
    padding-bottom: 5px;
}

.normalised {
    display: block;
    padding: 0;
    margin: 0;
    box-sizing: content-box;
    text-align: left;
    border-right: none;
    bottom: 0;
    border-left: none;
    border-top: none;
    border-bottom: 2px solid #DBDDE3;
    width: 100%;
    height: 60%;
}

.change-posiion{
    top: 0;
    right: 5.5px !important;
    transition: background 0.8s;
}

.drop-down::-webkit-calendar-picker-indicator {
    opacity: 100;
    color: #D1D3D4;
}

.line-height-1em {
    line-height: 1em;
}
.input-detail{
    background-color: transparent;
}
.label-detail {
    color: #B7BAC7;
    left: 0;
}

.vaild-check {
    color: #00C488;
}

.invalid-check {
    color: red;
}

.error{
    color: red;
}

.vaild-check, .invalid-check{
    right: 0;
    height: 30px;
    width: 30px;
}

.started-typing {
    animation: moveUp 0.1s ease forwards;
}

.no-typing {
    bottom: 0;
}

.input-detail {

}

.input-detail:focus{
    outline: none;
}

.form-input{
    height: 50px;
}


@keyframes moveUp {
    from {
        bottom: 0;
        font-size: 30px;
    }

    to {
        top: 0;
        font-size: 12px;
    }
}