.form-title{
    margin-top: 0;
    margin-bottom: 30px;
}

.input-element{
    margin-bottom: 20px;
}
.login-form{
    padding-bottom: 70px;
}

.login-form {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/*Media query*/
@media (max-width: 760px){
    .login-form {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }
}