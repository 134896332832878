.app-download{
	display: block;
	margin-top: 20px;
	font-size: 100%;
}

.download-detail {
	width: 50%;
}

.fourth-section {
	background-color: rgba(240, 192, 0, 0.06);
}

.download-image {
	width: 600px;
	object-fit: contain;
	height: 510px;
}

.absolute-props {
	bottom: 0;
	left: 51%;
}

/*Media query*/
@media (max-width: 760px){
	.fourth-section {
		text-align: center;

	}
	.download-detail {
		width: 100%;
	}

	.pr-5{
		padding-right: 5%;
	}
	.download-image{
		width: 100%;
		height: auto;
	}

	.app-download{
		margin: 20px auto 0 auto;
		font-weight: bold;
	}
}