.register-background {
    background-image: url("../../assets/images/authBg.png");
    background-size: cover;
}

    .register-background .big-text-description {
        padding-right: 0;
    }
/*Media query*/
@media (max-width: 760px) {
    .register-background {
    background: none;
    background-color: white;
}
    .register-background .auth-content-area{
        flex: 1;
    }
}