.radial, .last-radial{
    font-size: 24px;
}

.registration-step{
    padding: 0 40px;
}

.registration-text {
    margin-top: 10px;
}

.p-radial::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #DBDDE3;
}

.p-radial::after {
    content: "";
    width: 100%;
    height: 2px;
    
    background-color: #DBDDE3;
}

.p-radial.active-line::after {
    background-color: #FFB600;
    transition: background 0.8s;
}
