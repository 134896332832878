.form-container{
    width: 80%;
    background-color: white;
    border-radius: 10px;
    padding: 40px 30px;
}

.form-button {
    margin-top: 30px;
    font-size: 120%;
}

.back-press-button {
    background-color: white !important;
    border: 0.5px solid #131A4A;
    width: 49%;
    margin-right: 2%;
}

.back-press-button, .next-press-button {
    width: 49% !important;
}

/*Media query*/
@media (max-width: 760px) {
    .form-container {
        padding: 0;
    }

    .form-container {
        width: auto;
    }

}