.second-section {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
}

    .second-section::before {
        background-color: #13184D; 
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        content: "";
        opacity: 0.03;
        height: 100%;
        width: 100%;
    }

/*Media query*/
@media (max-width: 760px){
    .second-section{
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .description{
        display: block;
        margin-top: 18px;
        padding-right: 40px;
        padding-left: 40px;
        margin-bottom: 20px;
    }
}