.left-side {
    padding-top: 50px;
    padding-bottom: 17%;
    width: 50%;
    padding-left: 10%;
}

.left-side > div{
    padding-right: 40px;
}
.home-nav-link{
    text-decoration: none;
}

.home-login-link{
    margin-right: 20px;
}
.home-register-link {
    margin-left: 20px;
}
.right-side-nav {
    padding-top: 50px;
}

.right-side {
    background-image: url("../../assets/images/homeBg.png");
    background-size: cover;
    height: 100vh;
    width: 50%;
    padding-right: 10%
}

.header-description{
    margin-top: 15px;
    margin-bottom: 40px;
}

/*Media query*/
@media (max-width: 760px) {
    .left-side, .right-side {
        width: 100%;
    }
    .first-section {
        flex-wrap: wrap;
        text-align: center;
    }

    .left-side > div {
        padding-right: 0;
    }
    .first-section-store{
        justify-content: center;
    }

    .home-rider{
        font-size: 30px;
    }

    .left-side {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 70px;
        padding-bottom: 90px;
    }

    .right-side {
        height: 60vh;
    }
}