.auth-background{
    background-color: white;
    height: 100vh;
}

.nav-link{
    text-decoration: none;
}

.auth-content-area {
    padding-right: 80px;
}

.auth-child {
    width: 50%;
}
.auth-child-bottom{
    width: 50%;
}

.auth-child:first-child {
    margin-right: 5%;
}

.auth-text {
    font-size: 70px;
}

.big-text-description{
    padding-right: 20%;
}
/*Media query*/
@media (max-width: 760px) {
    .auth-content-area {
        flex-wrap: wrap;
        padding-right: 0;
        flex-direction: column;
        justify-content: start;
    }
    .remove-padding-mobile{
        padding-top: 0;
        padding-bottom: 0;
    }
    .flex-vertical-mobile{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .auth-child {
        width: 100%;
        display: flex;
    }

    .auth-child-bottom {
        width: 100%;
        flex: 1;
    }

    .auth-child:first-child {
        margin-right: 0;
    }


    .auth-text {
        color: #13184D !important;
        font-size: 30px;
        flex: none;
    }
}